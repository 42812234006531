<template>
  <div>
        <jy-query class="query_area" size="small">
            <jy-query-item label="企业名称">
                <el-input v-model="form.unitNa"></el-input>
            </jy-query-item>
            <jy-query-item label="消费流水号">
                <el-input v-model="form.seriesNu"></el-input>
            </jy-query-item>
            <jy-query-item label="工单编号">
                <el-input v-model="form.wOrderId"></el-input>
            </jy-query-item>
            <jy-query-item label="地磅名称">
                <el-input v-model="form.wbName"></el-input>
            </jy-query-item>
            <jy-query-item label="分拣中心">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in tUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="w in wasteTypeList" :key="w.wasteTId" :label="w.cName" :value="w.wasteTId" />
                </el-select>
            </jy-query-item>
            <jy-query-item label="交易时间">
                <el-date-picker type="daterange" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" :value="[form.createTBegin, form.createTEnd]" @input="onCreateTInput"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="清运时间">
                <el-date-picker type="daterange" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" :value="[form.grossWTStart, form.grossWTEnd]" @input="onGrossWTInput"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="query">查询</el-button>
                <el-button size="small" type="primary" @click="reset">重置</el-button>
                <!-- <el-button size="small" type="primary">导出</el-button> -->
            </template>
        </jy-query>
        <div>搜索结果合计：订单数 {{total}} 笔，交易消费总金额 {{totalSum}} 元</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                <jy-table-column label="交易时间" prop="tradeTime" min-width="160">
                </jy-table-column>
                <jy-table-column label="企业名称" prop="unitNa" min-width="150"></jy-table-column>
                <jy-table-column label="地址" prop="unitAddr" min-width="160"></jy-table-column>
                <jy-table-column label="消费流水号" prop="seriesNu" min-width="130"></jy-table-column>
                <jy-table-column label="支付方式" min-width="120">
                    <template v-slot="scope">
                        {{ getCHLName(scope.row.tradeWay) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="消费金额(元)" prop="amount" min-width="120"></jy-table-column>
                <jy-table-column label="账户余额(元)" prop="pBalance" min-width="120"></jy-table-column>
                <jy-table-column label="工单编号" prop="wOrderId" min-width="140"></jy-table-column>
                <jy-table-column label="地磅名称" prop="wbNa" min-width="140"></jy-table-column>
                <jy-table-column label="分拣中心" prop="tUnitNa" min-width="140"></jy-table-column>
                <jy-table-column label="清运时间" prop="grossWT" min-width="160"></jy-table-column>
                <jy-table-column label="清运总重量" prop="grossW" min-width="120">
                    <template v-slot="scope">
                        {{ getTotalNetWeight(scope.row.rWeight, scope.row.grossW, scope.row.tareW) }}{{ getUnit(scope.row.wasteTId) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="固废类别" prop="wasteTId" min-width="120">
                    <template v-slot="scope">
                        {{ getWasteTypeName(scope.row.wasteTId) }}
                    </template>
                </jy-table-column>
            </jy-table>
        </div>
        <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
  </div>
</template>

<script>
import {tradeWay} from '@/common/js/wordbook'
export default {
    data() {
        return {
            total: 0,
            totalSum:0,
            pageSize: 10,
            pageIndex: 1,
            loading: false,

            wasteTypeList: [],
            tUnitList: [],

            dataList: [],

            form: {
                unitNa: null,
                seriesNu: null,
                wOrderId: null,
                wbName: null,
                tUnitId: null,
                wasteTId: null,
                createTBegin: '',
                createTEnd: '',
                grossWTStart: '',
                grossWTEnd: ''
            }
        }
    },

    created() {
        this.getTUnitList();
        this.getWasteTypeList();
        this.getList();
    },

    methods: {
        query() {
            this.getList()
        },

        reset() {
            this.pageIndex = 1
            this.pageSize = 10
            Object.keys(this.form).forEach(key => {
                this.form[key] = ''
            })
            this.getList()
        },

        getList() {
            this.loading = true
            const { pageIndex, pageSize } = this
            this.$http.post('/finance/account/detailList', {
                io: 1,
                ...this.form,
                pageSize,
                pageIndex
            }).then(res => {
                this.total = res.detail.total;
                this.totalSum = res.detail.extraData.sum
                this.dataList = res.detail.data.reduce((prev , cur) => {
                    const res = Object.assign({}, ...Object.values(cur))
                    res.unitNa = cur.unitInfo && cur.unitInfo.cName;
                    res.unitAddr = cur.unitInfo && cur.unitInfo.addr;
                    res.tUnitNa = cur.tUnitInfo && cur.tUnitInfo.cName;
                    res.wbNa = cur.weighBridge && cur.weighBridge.cName;
                    prev.push(res);
                    return prev;
                }, [])
            }).finally(() => {
                this.loading = false;
            })
        },

        getWasteTypeList() {
            this.$http
                .post("/wasteType/listAll")
                .then(res => {
                    this.wasteTypeList = res.detail;
                })
        },

        getTUnitList() {
            this.$http.post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "3"
                })
                .then(res => {
                    this.tUnitList = res.detail
                })
        },

        getCHLName(chl) {
            let list = tradeWay()
            let item = list.find(one=>one.value==chl)
            return item ? item.label : '-';
        },

        getWasteTypeName(wasteTId) {
            let wasteType = this.wasteTypeList.find(each => each.wasteTId === wasteTId);
            if (wasteType) {
                return wasteType.cName
            }
            return '-';
        },

        getUnit(wasteTId) {
            let wasteType = this.wasteTypeList.find(each => each.wasteTId === wasteTId);
            if (wasteType) {
                return wasteType.unit
            }
            return '-';
        },

        getTotalNetWeight(rWeight, grossW, tareW) {
            return (rWeight || 0) + (grossW || 0) - (tareW || 0);
        },

        onCreateTInput(value) {
            [this.form.createTBegin, this.form.createTEnd] = value;
        },

        onGrossWTInput(value) {
            [this.form.grossWTStart, this.form.grossWTEnd] = value;
        },

        handleSizeChange(pageSize) {
            this.pageSize = pageSize
            this.query()
        },

        handleCurrentChange(pageIndex) {
            this.pageIndex = pageIndex
            this.query()
        }
    }
}
</script>

<style>

</style>